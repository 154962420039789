<template>
  <div class="gallery">
    <h3>{{ title }}</h3>
    <div class="gallery_container" v-if="images !== 'undefined'">
      <div class="gallery_image" v-for="index in formatImages.length" :key="index">
        <img
          :src="formatImages[index - 1]"
          :imageWidth="widths.split(splitTag)[index - 1]"
          :sourceSrc="sourceImages[index - 1]"
        />
        <span v-if="tags.split(splitTag)[index - 1]" v-html="tags.split(splitTag)[index - 1]"> </span>
      </div>
    </div>
  </div>
</template>

<script>
import {s3ThumbnailLink} from '@common/utils/utils';

export default {
  name: 'mac-gallery',
  props: ['title', 'images', 'tags', 'widths'],
  data: function () {
    return {
      splitTag: '【mcr】',
      files: [],
    };
  },
  computed: {
    formatWidths() {
      let arr = this.widths.split(this.splitTag);
      return arr;
    },
    sourceImages() {
      let arr = this.images.split(this.splitTag);
      var result = [];
      for (let a of arr) {
        if (a.indexOf('~@/') !== -1) {
          let imgSrc = `${a.replace('~@/assets/', '')}`;
          result.push(this.$getAsset('/assets/' + imgSrc));
        } else {
          result.push(a);
        }
      }
      return result;
    },
    formatImages() {
      let arr = this.images.split(this.splitTag);
      var result = [];
      let index = 0;
      for (let a of arr) {
        if (a.indexOf('~@/') !== -1) {
          let imgSrc = `${a.replace('~@/assets/', '')}`;
          result.push(this.$getAsset('/assets/' + imgSrc));
        } else {
          if (
            this.formatWidths[index] &&
            this.formatWidths[index] !== 'undefined' &&
            parseInt(this.formatWidths[index]) > 0
          ) {
            result.push(s3ThumbnailLink(a, this.formatWidths[index]));
          } else {
            result.push(a);
          }
        }
        index++;
      }
      return result;
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss"></style>
